import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import ContactForm from "../components/ContactFrom";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { IoMailOpenOutline } from "react-icons/io5";
import { FaMapMarked } from "react-icons/fa";

function CotactUs() {
  return (
    <section className="contact-us" id="contactus">
      <Container>
        <Row className="mb-3">
          <h2>Contact us</h2>
          <p>
            We're ready to assist you! If you're seeking quality electrical
            solutions for your company or construction project, feel free to
            reach out to us. We're available to discuss your specific needs and
            find the best way to collaborate.
          </p>
        </Row>
        <Row className="mb-3 services-container">
          <Col sm="4" className="mb-3 ">
            <div className="contact-card inline-block d-flex">
              <div className="block-icon justify-content-center">
                <IoMailOpenOutline />
              </div>
              <div className="justify-content-center block-text">
                <span>Email us</span>
                <br></br>
                <p>JAVIER.MORALES@ARIESGENERALBUILDERS.COM</p>
                </div>
            </div>
          </Col>

          <Col sm="4" className="mb-3 ">
            <div className="contact-card inline-block d-flex">
              <div className="block-icon justify-content-center">
                <MdOutlinePhoneInTalk />
              </div>
              <div className="justify-content-center block-text">
                <span>Call us</span>
                <p>+1 (520) 220-3607</p>
                </div>
            </div>
          </Col>
          <Col sm="4" className="mb-3 ">
            <div className="contact-card inline-block d-flex">
              <div className="block-icon justify-content-center">
                <FaMapMarked />
              </div>
              <div className="justify-content-center block-text">
                <span>Visit us</span>
                <p>1010 Pennsylvania St suit 206, Tucson Az 85714</p>
                </div>
            </div>
          </Col>
          
        </Row>
        <Row>
          <Col sm="6">
            <div className="contact-card">
              <ContactForm />
            </div>
          </Col>
          <Col sm="6">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3377.2155916694587!2d-110.96023801130372!3d32.17146060668401!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d67a634b26b193%3A0x4cc2c49f360767c8!2s1010%20E%20Pennsylvania%20St%2C%20Tucson%2C%20AZ%2085714%2C%20EE.%20UU.!5e0!3m2!1ses-419!2smx!4v1710389699165!5m2!1ses-419!2smx"
              className="google-map"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CotactUs;
