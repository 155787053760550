import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { IoMailOpenOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
function Footer() {
  return (
    <section className="footer">
      <Container>
        <Row>
          <Col sm={4}>
            <img src="img/logo/aries_gray_logo.png" style={{marginBottom:"20px",padding:"20px"}}></img>
            <p>
            At Aries Builders / South AZ Electric, we forge powerful connections. Building a bright future, one electrical connection at a time.
            </p>
          </Col>
          <Col sm={4}>
            <h4>Contact</h4>
            <Col xs="auto">
              <div style={{display:"-webkit-box"}} >
                <FaRegUser  size={"1.5rem"} color="#fff" stroke="2px"/>
                <div className="top-bar-container">
                  <p>Javier Morales</p>
                </div>
              </div>
            </Col>
            <Col xs="auto">
              <div style={{display:"-webkit-box"}} >
                <IoMailOpenOutline size={"1.5rem"} color="#fff" />
                <div className="top-bar-container mail-footer">
                  <p>javier.morales@ariesgeneralbuilders.com</p>
                </div>
              </div>
            </Col>
            <Col xs="auto">
              <div style={{display:"-webkit-box"}} >
                <MdOutlinePhoneInTalk size={"1.5rem"} color="#fff" />
                <div className="top-bar-container">
                  <p>+1 (520) 220-3607</p>
                </div>
              </div>
            </Col>
          </Col>
          <Col sm={4} className="sections-footer">
            <h4>Sections</h4>
            <a href="#home">Home</a>
            <br></br>
            <a href="#about">About</a>
            <br></br>
            <a href="#services">Services</a>
            <br></br>
            <a href="#projects"> Projects</a>
            <br></br>
            <a href="#contactus">Contact us</a>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Footer;
